import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// components
import Iconify from '../../../components/iconify';
import Swal from 'sweetalert2'
// ----------------------------------------------------------------------

export default function LoginForm()
{
  const auth = getAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;

    signInWithEmailAndPassword(auth, email, password)
    .then((response) => {
      // Signed in
      const {user} = response;
      if(!user.emailVerified)
      {
        sendEmailVerification(auth.currentUser)
        .then(async () => {
          await Swal.fire({
            title: 'Verify Email Address',
            text: 'An email has been sent to your inbox. Please verify your email address before your next login.',
            icon: 'info',
          })
          // Email verification sent!
          sessionStorage.setItem('Auth Token', user.getIdToken())
          navigate('/dashboard/app');
        });
      }
      else
      {
        sessionStorage.setItem('Auth Token', user.getIdToken())
        navigate('/dashboard/app');
      }
    })
    .catch(() => {
        navigate('/404', { replace: true });
    });
  };

  let inputType;
  useEffect(() => {
    const authToken = sessionStorage.getItem('Auth Token')
    if (!authToken) {
      navigate('/login');
      // eslint-disable-next-line react-hooks/exhaustive-deps
      inputType = []
    }
  },[inputType]);


  return (
    <form onSubmit={handleClick}>
      <>
        <Stack spacing={3}>
          <TextField name="email" label="Email address" />

          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}/>

        <LoadingButton fullWidth size="large" type="submit" variant="contained">
          Login
        </LoadingButton>
      </>
    </form>
  );
}
