import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

// @mui
import { Grid, Container, Typography } from '@mui/material';
// components
import { useEffect } from "react";
import { AppWidgetSummary } from "../sections/@dashboard/app";

// ----------------------------------------------------------------------
export default function DashboardAppPage() {
  const navigate = useNavigate();
  const string = 'davinci:ft-craftloop:custom-test-ai-assistant-ryhff-2023-02-28-18-24-22';
  const parts = string.split('-');
  const model = parts.slice(1, 6).join('-');
  const date = parts.slice(6, 9).join('-');
  const time = parts.slice(9).join(':');

  let inputType;
  useEffect(() => {
    const authToken = sessionStorage.getItem('Auth Token')

    if (!authToken) {
      navigate('/login');
      // eslint-disable-next-line react-hooks/exhaustive-deps
      inputType = []
    }
  }, [inputType])

  return (
    <>
      <Helmet>
        <title> Octopus </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <AppWidgetSummary title="AI Assistant Status" model={"Model: "+model} date={"Date: "+date} time={"Time: "+time} icon={'twemoji:robot'} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
