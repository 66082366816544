import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";

// ----------------------------------------------------------------------


export default function CraftTestEnvironmentPage() {
  if (navigator.mediaDevices.getUserMedia)
  {
    const constraints = {audio: true};

    // eslint-disable-next-line func-names
    const onSuccess = function (stream)
    {
      new MediaRecorder(stream);
    }

    // eslint-disable-next-line func-names
    const onError = function (err)
    {
      console.log(`The following error occurred: ${  err}`);
    }
    navigator.mediaDevices.getUserMedia(constraints).then(onSuccess, onError);
  }

  const navigate = useNavigate();
  let inputType;

  useEffect(() => {
    const authToken = sessionStorage.getItem('Auth Token')
    if (!authToken) {
      navigate('/login')
      // eslint-disable-next-line react-hooks/exhaustive-deps
      inputType = []
    }
  }, [inputType])

  window.addEventListener('message', (e) => {
    e.preventDefault();
    const iFrame = document.getElementById( 'chat-widget-craft' );
		// message that was passed from iframe page
		const message = e.data;
		iFrame.style.height = `${message.height}px`;
		iFrame.style.width = `${message.width}px`;
    iFrame.style.borderRadius = '25px';
    iFrame.style.margin = '25px';
	} , false);

  return (
    <>
      <Helmet>
        <title> Dashboard: Craft Shop </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Test Environment
          </Typography>
        </Stack>
        <Grid>
          <ul>
            Luke:
            <li>Can capable of answering questions (Example: tell me about the brand or what are you questions)</li>
            <li>Can sometimes give false information or confuse products with similar titles and product description</li>
            <li>Can recommend items, this is done manually (not dynamically - that will be an additional add-on to an AI assistant)</li>
            <li>Can guide the user shopping experience by engaging with user and giving his opinion on products.</li>
            <li>Currently, Luke don't have enough training data. So, be aware of how you ask him questions. (Example: show me them (previously talking about sweaters) -> Luke will not reply, because he has yet to learn specific context. Instead ask this way, even when you're deep in the conversation: show me your sweaters</li>
          </ul>
        </Grid>
        <hr/>
        <Grid>
          <ul>
            User:
            <li>Can close conversation by clicking the X icon</li>
            <li>Can hide conversation by clicking the minus icon</li>
            <li>Can now view images. Scroll or click and drag photo to see more. Clicking on the image will open a tab of the product page.</li>
            <li>Can reply to Luke responses</li>
          </ul>
        </Grid>

        <Stack>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe id="chat-widget-craft" src="https://iva-webwidget.web.app/" frameBorder="0" style={{position:"absolute", right: "0px", bottom:"0px"}}/>
        </Stack>
      </Container>
    </>
  );
}
